import type { Commands, Route, RouteContext } from "@vaadin/router";
import { defineCustomElement } from "@whilecat/core/utils/custom-elements-utils.js";
import { redirectTo, routeTo404, routeToLogin } from "@whilecat/core/utils/location-utils.ts";
import { isAuthenticated, isNotAuthenticated } from "@whilecat/services/authentication-service.ts";
import { checkIfLevelAccessible } from "@whilecat/services/user-course-service.ts";

const tsCourses = ["shell", "concurrency", "junit"];

export const ROUTES: Route[] = [
    {
        path: "/",
        component: "landing-page",
        action: async () => {
            await import("./pages/landing/landing.js");
        },
    },
    {
        path: "/login",
        component: "login-form",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/login-form.ts");
        },
    },
    {
        path: "/registration",
        component: "registration-form",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/registration-form.ts");
        },
    },
    {
        path: "/restore-password",
        component: "forgot-password-form",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/forgot-password-form.ts");
        },
    },
    {
        path: "/reset-password",
        component: "reset-password",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/reset-password.ts");
        },
    },
    {
        path: "/confirm",
        component: "confirm-registration-form",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/confirm-registration-form.ts");
        },
    },
    {
        path: "/authentication/github",
        component: "external-authentication-processing",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/external-authentication-processing.ts");
        },
    },
    {
        path: "/authentication/google",
        component: "external-authentication-processing",
        action: async () => {
            if (isAuthenticated()) {
                return redirectTo("/courses");
            }
            await import("./pages/security/external-authentication-processing.ts");
        },
    },
    {
        path: "/courses",
        component: "course-list",
        action: async () => {
            await import("./pages/courses/course-list.ts");
        },
    },
    {
        path: "/prices",
        component: "prices-block",
        action: async () => {
            await import("./pages/prices/prices-block.js");
        },
    },
    {
        path: "/repetition",
        component: "repetition-page",
        action: async (context: RouteContext, commands: Commands) => {
            if (isNotAuthenticated()) {
                return routeToLogin(commands);
            }
            await import("./pages/repetition/repetition.ts");
        },
    },
    {
        path: "/courses/:section/:course",
        action: async (context: RouteContext, commands: Commands) => {
            const course = context.params.course as string;
            const section = context.params.section;

            const ext = tsCourses.includes(course) ? "ts" : "js";
            try {
                await import(`./content/${section}/${course}/levels.${ext}`);
            } catch (error) {
                console.error(error);
                return routeTo404(commands);
            }
            return commands.component(`${section}-${course}-levels`);
        },
    },
    {
        path: "/courses/:section/:course/lesson-:lessonNumber",
        action: async (context: RouteContext, commands: Commands) => {
            const courseName = context.params.course as string;
            const section = context.params.section;
            const lessonNumber = context.params.lessonNumber;

            if (isNotAuthenticated()) {
                return routeToLogin(commands);
            }

            const ext = tsCourses.includes(courseName) ? "ts" : "js";

            const course = await import(`./content/${section}/${courseName}/levels.${ext}`);
            const lesson = await import(`./content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`);

            const accessible = await checkIfLevelAccessible(course.getCourseUuid(), lesson.getLevelUuid());
            if (!accessible) {
                return routeTo404(commands);
            }

            try {
                await import(`./content/${section}/${courseName}/lesson-${lessonNumber}.${ext}`);
            } catch (error) {
                console.error(error);
                return routeTo404(commands);
            }

            if (Object.hasOwn(lesson, "getComponent")) {
                defineCustomElement(`${section}-${courseName}-lesson-${lessonNumber}`, await lesson.getComponent());
            }
            return commands.component(`${section}-${courseName}-lesson-${lessonNumber}`);
        },
    },
    {
        path: "/404",
        component: "not-found",
        action: async (context: RouteContext) => {
            console.error("Redirected to not found page: ", context);
            await import("./pages/404/not-found.ts");
        },
    },
    {
        path: "/profile",
        component: "user-profile",
        action: async () => {
            await import("./pages/user/user-profile.ts");
        },
    },
    {
        path: "/reach-us",
        component: "feedback-form",
        action: async () => {
            await import("./pages/feedback/feedback.ts");
        },
    },
    {
        path: "/level-viewer(.*)",
        component: "level-viewer",
        action: async () => {
            await import("./dev/level-viewer.js");
        },
    },
    {
        path: "(.*)",
        component: "not-found",
        action: async (context: RouteContext, commands: Commands) => {
            console.error("Not found page: ", context);
            return routeTo404(commands);
        },
    },
];
