import type { Commands } from "@vaadin/router";
import { requireNotNull } from "@whilecat/core/utils/validation.ts";
import type { LevelInfo } from "@whilecat/services/dto/level-info.ts";

/**
 * @returns The full URL of the page, e.g. 'http://localhost/courses/java/concurrency'
 */
export function getCurrentLocation() {
    return window.location.href;
}

/**
 * @returns URL Path of the page, e.g. '/courses/java/concurrency'
 */
export function getCurrentPath() {
    return window.location.pathname;
}

export function redirectTo(newLocation: string) {
    window.location.assign(newLocation);
}

export function redirectToCourses() {
    redirectTo("/courses");
}

export function redirectToProfile() {
    redirectTo("/profile");
}

export function redirectToLogin() {
    redirectTo("/login");
}

export function routeTo404(commands: Commands) {
    requireNotNull(commands);
    return commands.redirect("/404");
}

export function routeToLogin(commands: Commands) {
    requireNotNull(commands);
    localStorage.setItem("previousPage", window.location.href);
    return commands.redirect(`/login`);
}

export function redirectToLevel(level: LevelInfo) {
    const url = window.location.origin + level.levelUrl + "?completed=" + level.completed;
    window.history.pushState(null, document.title, url);
    window.dispatchEvent(new PopStateEvent("popstate"));
}

export function getQueryParameter(name: string) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
}

export function openNewTab(url: string) {
    window.open(url, "_blank");
}

/**
 * @param url - source url from import.meta.url
 * @returns - directory containing file
 */
export function getCurrentDirectoryPath(url: string) {
    return new URL(".", url).href;
}
