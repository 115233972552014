import {
    deleteUserId,
    getUserId,
    setRegistrationTokenCookie,
    setResetPasswordToken,
} from "@whilecat/core/utils/cookie-utils.ts";
import { getCurrentLocation, getQueryParameter, redirectTo } from "@whilecat/core/utils/location-utils.ts";
import { isBlank } from "@whilecat/core/utils/string.ts";
import { assertNotNull, requireNotBlank } from "@whilecat/core/utils/validation.ts";
import type { paths } from "@whilecat/generated/openapi/authentication.ts";
import { getClient } from "@whilecat/services/client-utils.ts";
import type { ErrorResponse } from "@whilecat/services/dto/error-response.ts";
import { HttpStatus } from "@whilecat/services/response-codes.ts";

const client = getClient<paths>();

export async function login(email: string, password: string) {
    // parseAs needed for the lib to not read the response body, which can only be read once.
    const { response } = await client.POST("/public/authentication/", {
        body: { email, password },
        parseAs: "stream",
    });

    return response;
}

export async function logout() {
    await client.DELETE("/public/authentication/");
    deleteUserId();
    redirectTo(getCurrentLocation());
}

export async function register(email: string, password: string) {
    // parseAs needed for the lib to not read the response body, which can only be read once.
    const { response } = await client.POST("/public/authentication/registration", {
        body: { email, password },
        parseAs: "stream",
    });

    return response;
}

export async function completeRegistration() {
    setRegistrationTokenCookie(requireNotBlank(getQueryParameter("token")));

    // parseAs needed for the lib to not read the response body, which can only be read once.
    const { response } = await client.POST("/public/authentication/registration/complete", { parseAs: "stream" });

    return response;
}

export async function resendConfirmationLetter(email?: string) {
    const useEmail = requireNotBlank(isBlank(email) ? getQueryParameter("email") : email);

    const { data, response } = await client.POST("/public/authentication/registration/resend", {
        params: { query: { email: useEmail } },
    });

    return { data, response };
}

export async function sendPasswordRecoveryLetter(email?: string) {
    const useEmail = requireNotBlank(isBlank(email) ? getQueryParameter("email") : email);

    const { data, response } = await client.POST("/public/authentication/recover-password", {
        params: { query: { email: useEmail } },
    });

    return { data, response };
}

export async function getGoogleAuthenticationLink() {
    const { response } = await client.withRetries(2).GET("/public/authentication/google/link", {
        headers: { accept: "text/plain" },
        parseAs: "stream",
    });
    return response;
}

export async function getGitHubAuthenticationLink() {
    const { response } = await client.withRetries(2).GET("/public/authentication/github/link", {
        headers: { accept: "text/plain" },
        parseAs: "stream",
    });
    return response;
}

async function externalAuthentication(type: "github" | "google"): Promise<Response | ErrorResponse> {
    const code = getQueryParameter("code");
    assertNotNull(code);

    const url = type === "github" ? "/public/authentication/github" : "/public/authentication/google";
    const { response, data, error } = await client.POST(url, { params: { query: { code } } });
    if (response.ok) {
        redirectTo("/courses");
    } else if (response.status === HttpStatus.BAD_REQUEST) {
        return error! as ErrorResponse;
    }
    return response;
}

export async function resetPassword(password: string) {
    const token = requireNotBlank(getQueryParameter("token"));
    const email = requireNotBlank(getQueryParameter("email"));

    requireNotBlank(password);

    setResetPasswordToken(token);

    return await client.POST("/public/authentication/reset-password", { body: { email, password } });
}

export async function updatePassword(email: string, password: string) {
    requireNotBlank(email);
    requireNotBlank(password);

    return await client.POST("/public/authentication/reset-password", { body: { email, password } });
}

export async function googleAuthenticationProceed() {
    return await externalAuthentication("google");
}

export async function gitHubAuthenticationProceed() {
    return await externalAuthentication("github");
}

export function redirectToLoginOnUnauthorized() {
    if (isNotAuthenticated()) {
        redirectTo(`/login?redirect=${getCurrentLocation()}`);
        return true;
    }
    return false;
}

export function isAuthenticated() {
    return !isNotAuthenticated();
}

export function isNotAuthenticated() {
    return !getUserId();
}
