import { isNotBlank } from "@whilecat/core/utils/string.ts";
import { sleep } from "@whilecat/core/utils/time.ts";
import { requireNotBlank } from "@whilecat/core/utils/validation.js";
import createClient, { type Client, type Middleware } from "openapi-fetch";
let BASE_URL_TEST = "";

export function setTestBaseUrl(baseUrl: string) {
    requireNotBlank(baseUrl);
    BASE_URL_TEST = baseUrl;
}

// All service files share the same underlying client. But since they all pass different types to
// getClient<paths>(), typechecking still works!
let client: CatClient<any>;

export function getClient<T extends {}>(timezone?: string) {
    if (client == null) {
        client = createWhileCatClient<T>(timezone);
    }
    return client as CatClient<T>;
}

export function createWhileCatClient<T extends {}>(timezone?: string) {
    const tz = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseUrl: string = isNotBlank(BASE_URL_TEST) ? (BASE_URL_TEST as string) : "/api";
    return new CatClient(createClient<T>({ baseUrl: baseUrl, headers: { TimeZone: tz } }));
}

export class CatClient<T extends {}> {
    client: Client<T>;
    retries: number;

    constructor(client: Client<T>, params: { retries?: number } = {}) {
        this.client = client;
        this.retries = params.retries ?? 0;
    }

    withRetries(n: number) {
        // avoid copying the actual client.
        return new CatClient<T>(this.client, { retries: n });
    }

    // This was the only way I found to copy the typings of the original client.
    GET: Client<T>["GET"] = async (url, ...params) =>
        await retry(async () => await this.client.GET(url, ...params), this.retries);
    POST: Client<T>["POST"] = async (url, ...params) =>
        await retry(async () => await this.client.POST(url, ...params), this.retries);
    PUT: Client<T>["PUT"] = async (url, ...params) =>
        await retry(async () => await this.client.PUT(url, ...params), this.retries);
    PATCH: Client<T>["PATCH"] = async (url, ...params) =>
        await retry(async () => await this.client.PATCH(url, ...params), this.retries);
    DELETE: Client<T>["DELETE"] = async (url, ...params) =>
        await retry(async () => await this.client.DELETE(url, ...params), this.retries);
    HEAD: Client<T>["HEAD"] = async (url, ...params) =>
        await retry(async () => await this.client.HEAD(url, ...params), this.retries);
    OPTIONS: Client<T>["OPTIONS"] = async (url, ...params) =>
        await retry(async () => await this.client.OPTIONS(url, ...params), this.retries);
    TRACE: Client<T>["TRACE"] = async (url, ...params) =>
        await retry(async () => await this.client.TRACE(url, ...params), this.retries);

    use(...middleware: Middleware[]) {
        return this.client.use(...middleware);
    }

    eject(...middleware: Middleware[]) {
        return this.client.eject(...middleware);
    }
}

// @ts-ignore
const IS_PROD = import.meta.env.PROD;
const RETRY_SLEEP = IS_PROD ? 500 : 1;

export async function retry<T>(fn: () => T, maxRetries = 0, retryCount = 0) {
    try {
        return await fn();
    } catch (error) {
        if (retryCount >= maxRetries) {
            throw error;
        }
        await sleep(RETRY_SLEEP * (retryCount + 1));
        return await retry(fn, maxRetries, retryCount + 1);
    }
}
