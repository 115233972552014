import { setBasePath } from "@shoelace-style/shoelace";
import { Router } from "@vaadin/router";
import { SupportedLanguage, setCurrentLanguage } from "@whilecat/core/localization/localization-utils.ts";
import { initSentry, setSentryContext } from "@whilecat/core/utils/sentry/init.ts";
import { ROUTES } from "./routes.ts";

import "@shoelace-style/shoelace";
import "@shoelace-style/shoelace/dist/themes/dark.css";

setBasePath("/shoelace/dist/assets");

// @ts-ignore
const lang = import.meta.env.VITE_WHILECAT_LANG;
setCurrentLanguage(lang || SupportedLanguage.RU);

// @ts-ignore
if (import.meta.env.PROD) {
    initSentry();
    setSentryContext();
}

// https://vite.dev/guide/build.html#load-error-handling
window.addEventListener("vite:preloadError", () => {
    window.location.reload();
});

const outlet = document.getElementById("outlet");
const router = new Router(outlet);
router.setRoutes(ROUTES);
