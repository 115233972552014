export function getLocalTimeString(offsetMilliseconds: number = 0) {
    const now = new Date();
    now.setMilliseconds(now.getMilliseconds() + offsetMilliseconds);

    // Get hours, minutes, and seconds
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds() + offsetMilliseconds).padStart(4, "0");

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function schedule(callback: Function) {
    setTimeout(callback, 100);
}

export const sleep = (time: number) => new Promise<void>(resolve => setTimeout(resolve, time));
