import * as Sentry from "@sentry/browser";
import { getUserId } from "@whilecat/core/utils/cookie-utils.ts";

// @ts-ignore
const IS_PROD = import.meta.env.PROD;
// Ideally "/api/4508610547286096/envelope/" should be added by Caddy, but I couldn't
// get it to work.
const TUNNEL = "/public/sentry-tunnel/api/4508610547286096/envelope/";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://8cb74c7dea72e013c52a12ddc21087bf@o4508610542108672.ingest.de.sentry.io/4508610547286096",
        maxBreadcrumbs: 50,
        debug: true,
        ...(IS_PROD ? { tunnel: TUNNEL } : {}),
        environment: IS_PROD ? "production" : "local",
        integrations: [Sentry.reportingObserverIntegration()],
    });
};

export const setSentryContext = () => {
    try {
        const id = getUserId();
        if (id) {
            Sentry.setUser({ id });
        }
    } catch (e) {
        Sentry.captureException(e);
    }
};
