import { configureLocalization } from "@lit/localize";

import { sourceLocale, targetLocales } from "../../../translations/locale-codes.ts";

export enum SupportedLanguage {
    RU = "ru_RU",
    EN = "en",
}

const values: SupportedLanguage[] = Object.values(SupportedLanguage);

export function getSupportedLanguages() {
    return values;
}

export function setCurrentLanguage(language: SupportedLanguage) {
    return setLocale(language.toString());
}

export function getCurrentLanguage(): SupportedLanguage {
    return getLocale() as SupportedLanguage;
}

const { getLocale, setLocale } = configureLocalization({
    sourceLocale,
    targetLocales,
    loadLocale: locale => import(`../../../translations/locales/${locale}.ts`),
});

/*
    1. При первом запуске посмотреть локаль системы/по локации - либо ру/английский
    2. сохранить этот выбор в куки
    3. сделать кнопочку для переключения, которая будет сохранять в куки
    4.
 */
