import type { paths } from "@whilecat/generated/openapi/repetition.ts";
import { HttpStatus } from "@whilecat/services/response-codes.ts";
import { getClient } from "./client-utils.ts";

const client = getClient<paths>();

export async function getTasksToRepeat(courseId: string | null) {
    const all = await getAllTasksToRepeat();
    return courseId ? all.filter(x => x.courseUuid == courseId) : all;
}

export async function getAllTasksToRepeat() {
    const { data, error } = await client.withRetries(2).GET("/user/repetition");
    if (error) {
        throw new Error(error);
    }
    return data!;
}

export async function scheduleNextRepeat(courseUuid: string, levelUuid: string, taskUuid: string) {
    const payload = { courseUuid, levelUuid, taskUuid };
    const { response } = await client.PUT("/user/repetition", { body: payload });
    return response;
}

export async function hasSomethingToRepeat() {
    const { response } = await client.GET("/user/repetition/available", { retries: 3 });
    return response.status === HttpStatus.FOUND;
}
