import { requireNotNull } from "@whilecat/core/utils/validation.js";

export function getCSSVariable(variable: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

export function getCSSVariablePixels(variable: string) {
    return Number(getCSSVariable(variable).replace("px", ""));
}

export function scrollToElement(element: HTMLElement, offsetFromTop: number) {
    requireNotNull(element);

    const elementRect = element.getBoundingClientRect();
    const elementTop = elementRect.top + window.scrollY;

    window.scrollTo({
        top: elementTop - offsetFromTop,
        behavior: "smooth",
    });
}

export function scrollToView(element: HTMLElement, strategy: ScrollLogicalPosition = "start") {
    requireNotNull(element);
    element.scrollIntoView({ behavior: "smooth", block: strategy, inline: "nearest" });
}

export function isMobile() {
    return window.screen.width <= 1200;
}
