import { requireNotNull } from "@whilecat/core/utils/validation.ts";
import Cookies from "./cookies/cookie-utils.js";

const USER_ID_KEY = "userId";
const USER_ID_ATTRIBUTES = { path: "/", sameSite: "strict", expires: 1 };

const REGISTRATION_TOKEN_COOKIE = "registrationToken";
const RESET_PASSWORD_TOKEN = "resetPasswordToken";

export function getCookie(name: string | null) {
    name = requireNotNull(name);

    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

export function getCookieOr(name: string, defaultValue: boolean) {
    const cookie = getCookie(name);
    if (cookie === undefined || cookie === null) return defaultValue;
    return cookie;
}

export function setCookie(name: string, value: string) {
    Cookies.set(name, value);
}

export function setShortTimeCookie(name: string, value: string) {
    Cookies.set(name, value, { path: "/", sameSite: "strict", expires: 0.1 });
}

export function deleteCookie(name: string, path = "/", domain = "") {
    Cookies.remove(name, { domain: `${domain}`, path: `${path}` });
}

export function getUserId(): string | undefined {
    return Cookies.get(USER_ID_KEY, USER_ID_ATTRIBUTES);
}

export function setUserId(id: string) {
    Cookies.set(USER_ID_KEY, id, USER_ID_ATTRIBUTES);
}

export function deleteUserId() {
    Cookies.remove(USER_ID_KEY, USER_ID_ATTRIBUTES);
}

export function setRegistrationTokenCookie(value: string) {
    setShortTimeCookie(REGISTRATION_TOKEN_COOKIE, value);
}

export function setResetPasswordToken(value: string) {
    setShortTimeCookie(RESET_PASSWORD_TOKEN, value);
}
