import type { paths } from "@whilecat/generated/openapi/course.ts";
import { Course } from "@whilecat/services/dto/course.ts";
import { LevelInfo } from "@whilecat/services/dto/level-info.ts";
import { getClient } from "./client-utils.ts";

const client = getClient<paths>();

async function getCoursesMeshRequest() {
    return await client.withRetries(2).GET("/public/mesh/course");
}

export async function getCoursesMesh() {
    const { response, data } = await getCoursesMeshRequest();
    if (!data) {
        throw new Error(response.statusText);
    }

    return data
        .filter(c => c.course.id !== 5)
        .map(
            courseInfo =>
                new Course(
                    courseInfo.course.id,
                    courseInfo.course.uuid,
                    courseInfo.course.name,
                    courseInfo.course.url,
                    courseInfo.course.sourcePath,
                    courseInfo.enrolled,
                ),
        );
}

export async function getCourseLevels(courseUuid: string) {
    try {
        const { response, data } = await client.withRetries(2).GET("/public/course/{courseUuid}/level", {
            params: { path: { courseUuid } },
        });

        if (!data) {
            throw new Error(response.statusText);
        }

        const levels = data.map(level => new LevelInfo(level.id, level.name, level.url, level.sourcePath, false));
        return { ok: true, levels };
    } catch (e) {
        console.error(e);
        return { ok: false, levels: [] };
    }
}
